import React, { Component } from "react";
import Carousel from "nuka-carousel";

import "./slider_extensions.css";
import slideImg1 from "../../assets/images/Ciccios_Site_Bildspel_1.jpg";
import slideImg2 from "../../assets/images/Ciccios_Site_Bildspel_2.jpg";
import slideImg3 from "../../assets/images/Ciccios_Site_Bildspel_3.jpg";
import slideImg4 from "../../assets/images/Ciccios_Site_Bildspel_4.jpg";
import slideImg5 from "../../assets/images/Ciccios_Site_Bildspel_5.jpg";

class Slider extends Component {
  state = {};

  render() {
    return (
      <Carousel>
        <img alt="pasta" src={slideImg1} />
        <img alt="bar with people" src={slideImg2} />
        <img alt="drink and food" src={slideImg3} />
        <img alt="langoustine" src={slideImg4} />
        <img alt="chef" src={slideImg5} />
      </Carousel>
    );
  }
}

export default Slider;
