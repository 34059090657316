import React from 'react';
import Logo from './Logo'
import styled from 'styled-components'
import { breakpoints } from '../styles'


const LogoWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${p => p.width}px;
    @media(${ breakpoints.tablet.max }) {
        width: 230px;
    }
    `


const MainLogo = props => {
    return (
        <LogoWrapper width={props.width}>
            <Logo {...props} />
        </LogoWrapper>
    );
}

export default MainLogo;
