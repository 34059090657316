import React, { Component } from "react";
import { injectGlobal } from "styled-components";
import MainLogo from "./Logo/MainLogo";
import { colors, breakpoints } from "./styles";
import GravurCondLightW2 from "./assets/fonts/GravurCondensed-Light.woff2";
import GravurCondLightW from "./assets/fonts/GravurCondensed-Light.woff";
import CentralAvenueW from "./assets/fonts/central-avenue-webfont.woff";
import CentralAvenueW2 from "./assets/fonts/central-avenue-webfont.woff2";
import LandingPane from "./components/LandingPane";
import Story from "./components/Story";
import MenuesList from "./components/MenuesList";
import Slider from "./components/Slider";
import Find from "./components/Find";
import Footer from "./components/Footer";

injectGlobal`
  @font-face {
    font-family: 'GravurCondLight';
    src: url(${GravurCondLightW2}), url(${GravurCondLightW});
  }
  @font-face {
    font-family: 'CentralAvenue';
    src: url(${CentralAvenueW2}), url(${CentralAvenueW});
  }
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
    }
  .hide-desktop {
    @media (${breakpoints.tablet.min}) {
        display: none;
    }
  }
  .logo-svg {
      max-width: 100%;
      height: auto;
  }
  p {
}
body {
    margin: 0;
    line-height: 1.4em;
    font-family: 'GravurCondLight', sans-serif;
    color: ${colors.dark};
    background: ${colors.bone};
  }`;

class Page extends Component {
  state = {
    modalVisible: false
  };

  setModalState = modalVisible => {
    this.setState({ modalVisible });
  };
  render() {
    return (
      <div>
        <LandingPane
          modalVisible={this.state.modalVisible}
          setModalState={this.setModalState}
        >
          <MainLogo width="300" color={colors.apricot} />
        </LandingPane>
        <Story />
        <MenuesList />
        <Slider />
        <Find />
        <Footer />
      </div>
    );
  }
}

export default Page;
