import React, { Component } from "react";
import "./App.css";
import { Switch, Route, HashRouter as Router } from "react-router-dom";
import BookingModal from "./components/bookingModal";
import Page from "./Page";
import { unregister } from "./registerServiceWorker";

unregister();
class App extends Component {
  render() {
    console.log(".");
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Page} history={this.props.history} />
          <Route
            exact
            path="/booking"
            component={BookingModal}
            history={this.props.history}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
