import React from 'react'
import styled from 'styled-components' 
import { mediaUp, breakpoints } from '../styles'

const StoryArticle = styled.article`
    padding: 4rem 1rem;
    @media (${breakpoints.tablet.max}) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 1.1em;
    `
const StoryHeading = styled.h2`
    font-family: 'CentralAvenue';
    font-size: 3rem;
    line-height: 1em;
    margin-top: 0;
    `
const TextWrapper = styled.div`
    ${ mediaUp.tablet `
        width: 38rem;
    `} 
    `

const Story = () => {
    return (
        <StoryArticle>
            <TextWrapper>
                <StoryHeading>WELCOME to CICCIO’S</StoryHeading>
                <p>The name Ciccio's is derived from Italian and means “chubby boy” in a tender way. For us, it symbolizes a lust for all the good things in life. The restaurant and our menu has it’s roots in the Italian-American cuisine. Our main inspiration comes from New York's Little Italy – A timeless melting pot where classic dishes are constantly reinvented and brought to new life. We want to create the perfect place to meet for every occasion and for every meal of the day, by erasing the boundaries between where you work, socialize, eat and drink.</p>
            </TextWrapper>
        </StoryArticle>
    )
}

export default Story