import Axios from "axios";

const api = Axios.create({
  // baseURL: "https://italiano.se/wp-json/",
  baseURL: "http://italianostage.wpengine.com/wp-json/",
  timeout: 1000,
  headers: { Accept: "application/json" },
});

export default {
  getMenuUrls: () => api.get("acf/v3/ciccio_menu"),
  cdnDomain: "2otheb3qbvxu2oju51fl94yu.wpengine.netdna-cdn.com",
  sslCdnDomain: "2otheb3qbvxu2oju51fl94yu-wpengine.netdna-ssl.com",
};
