import styled, { css } from 'styled-components'
import colors from './colors'


// style-utils.js

const hide = hide => hide ? `display: none;` : ``
  
const sizes = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 376
}

const breakpoints = Object.keys(sizes).reduce((acc, label) => {
  const emSize = sizes[label] / 16
  const emSizePlus = (sizes[label] + 1) / 16
  acc[label] = {
    max: `max-width: ${emSize}em`,
    min: `min-width: ${emSizePlus}em`
  }
  return acc
}, {})

// iterate through the sizes and create a media template
const mediaDown = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
    `
  return accumulator
}, {})

const mediaUp = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = (sizes[label] + 1) / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
    `
  return accumulator
}, {})

const Section = styled.section`
    display: flex;
    padding: 5rem 1rem;
    @media (${breakpoints.tablet.max}) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    `

const StyledLink = styled.a`
    color: inherit;
    text-decoration: none;
    &:visited, &:focus, &:hover {
        color: inherit;
    }
    &:hover {
        text-decoration: underline;
    }
    `
export { Section, colors, mediaDown, mediaUp, sizes, breakpoints, hide, StyledLink }