import React, { Component } from "react";
import styled from "styled-components";
import { colors, mediaDown } from "../styles";
import Nav from "./nav/Nav";
import landingBg from "../assets/images/Ciccios_Site_Header.jpg";

const StyledPane = styled.section`
  background-color: ${colors.dark};
  background: url(${landingBg}) no-repeat center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  max-height: 67.5rem;
  ${mediaDown.phone`
        max-height: 667px;
        height: calc(100vh - 4rem);
        `}
  position: relative;
  color: ${colors.apricot};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const TopBar = styled.div`
  padding-top: 3rem;
  text-align: center;
  font-size: 1.1rem;
`;
class LandingPane extends Component {
  render() {
    return (
      <StyledPane>
        <TopBar>Nybrogatan 21, 114 39 Stockholm</TopBar>
        {this.props.children}
        <Nav />
      </StyledPane>
    );
  }
}

export default LandingPane;
