import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors, breakpoints } from "../../styles";
import scrollToHash from "./animateScroll";

export const navItemStyle = props => `
    color: ${colors.apricot};
    @media (${breakpoints.tablet.min}) {
        font-size: 2rem;
    }
    @media (${breakpoints.tablet.max}) {
        text-align: center;
    }
    text-transform: uppercase;
    font-family: 'CentralAvenue';
    cursor: pointer;
    padding: 2rem;  
    text-decoration: none;
    `;

const Bar = styled.div`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  @media (${breakpoints.tablet.min}) {
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;
    backface-visibility: hidden;
    will-change: position;
    max-width: 75rem;
    justify-content: space-between;
  }
  display: flex;
  margin: 0 auto;
`;
export class NavItem extends Component {
  render() {
    const { className, children, to, handleClick } = this.props;
    return (
      <Link className={className} onClick={e => handleClick(to)} to={to}>
        {children}
      </Link>
    );
  }
}

export const StyledNavItem = styled(NavItem)`
    ${navItemStyle}
    @media (${breakpoints.tablet.min}) {
        min-width: ${props => (props.noMinWidth ? 0 : "10rem")};
        &:first-child {
            text-align: right;
        }
    }
    `;
class NavItems extends Component {
  navigate = to => {
    scrollToHash(to);
  };
  render() {
    return (
      <Bar {...this.props}>
        <StyledNavItem handleClick={this.navigate} to="#menus">
          Menus
        </StyledNavItem>
        <StyledNavItem
          handleClick={this.navigate}
          className="hide-desktop"
          to="#contact"
        >
          Contact
        </StyledNavItem>
        <StyledNavItem handleClick={to => {}} to="/booking">
          Book Table
        </StyledNavItem>
      </Bar>
    );
  }
}

export default NavItems;
