import React from "react";
import { Section, colors, mediaDown } from "../styles";
import styled from "styled-components";
import Map from "./Map";

const FindSection = styled(Section)`
  background: ${colors.dark};
  color: ${colors.bone};
  flex-direction: column;
  align-items: center;
  color: ${colors.apricot};
`;
const MapWrapper = styled.div`
  max-width: 50rem;
  width: 100%;
  height: 22.5rem;
  position: relative;
`;
const Address = styled.address`
  font-style: normal;
  font-family: "CentralAvenue";
  font-size: 3rem;
  line-height: 1em;
  text-align: center;
  margin-bottom: 3rem;
  ${mediaDown.tablet`
        font-size: 1.5rem;
        line-height: 1.1em;
    `}
`;
const Call = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;
const Column = styled.div`
  flex-basis: 50%;
  text-align: center;
`;

const Info = styled.div`
  display: flex;
  margin-top: 5rem;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  ${mediaDown.tablet`
        flex-direction: column;
        text-align: center;
        margin-top: 2.5rem;
    `}
  ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }
`;

const Find = () => {
  return (
    <React.Fragment>
      <span id="contact" />
      <FindSection>
        <Address>
          NYBROGATAN No 21
          <br />
          114 39 STOCKHOLM
          <br />
          <Call href="tel:08-661 89 76">08-661 89 76</Call>
        </Address>
        <MapWrapper>
          <Map />
        </MapWrapper>
        <Info>
          <Column>
            <ul>
              <li>Tuesday - 5 pm - 12 pm</li>
              <li>Wednesday - Saturday 5 pm - 1 am</li>
            </ul>
          </Column>
        </Info>
      </FindSection>
    </React.Fragment>
  );
};

export default Find;
