import React from 'react';

const ItalianoLogo = ({ color, width }) => (
    <svg
        width={`${width || 293}px`}
        height={`${(width * 62) / 293 || 62}px`}
        viewBox="0 0 293 62"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <polygon id="path-1" points="0 0 45 0 45 61 0 61"></polygon>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Italiano_Desktop_Landning-(Design)"
                transform="translate(-301.000000, -439.000000)"
            >
                <g id="Group-11" transform="translate(301.803598, 439.546426)">
                    <polygon
                        id="Fill-1"
                        fill={color || "#FFFFFF"}
                        points="0 59 21 59 21 0 0 0"
                    ></polygon>
                    <polygon
                        id="Fill-2"
                        fill={color || "#FFFFFF"}
                        points="57 14.7498913 50.7734602 14.7498913 50.7734602 59 30.2265398 59 30.2265398 14.7498913 24 14.7498913 24 0 57 0"
                    ></polygon>
                    <path
                        d="M76.5910532,19.9737862 L76.4697918,19.9737862 L74.1079784,34.3550688 L78.8924501,34.3550688 L76.5910532,19.9737862 Z M83.3135659,59 L81.6176193,49.7198877 L71.3823807,49.7198877 L69.6868626,59 L54,59 L65.6286743,0 L87.3717542,0 L99,59 L83.3135659,59 Z"
                        id="Fill-3"
                        fill={color || "#FFFFFF"}
                    ></path>
                    <polygon
                        id="Fill-4"
                        fill={color || "#FFFFFF"}
                        points="120.953439 44.2501087 127 44.2501087 127 59 101 59 101 0 120.953439 0"
                    ></polygon>
                    <polygon
                        id="Fill-5"
                        fill={color || "#FFFFFF"}
                        points="132 59 153 59 153 0 132 0"
                    ></polygon>
                    <path
                        d="M177.59084,19.9737862 L177.469577,19.9737862 L175.107741,34.3550688 L179.892259,34.3550688 L177.59084,19.9737862 Z M184.313416,59 L182.617454,49.7198877 L172.382118,49.7198877 L170.686155,59 L155,59 L166.628357,0 L188.371215,0 L200,59 L184.313416,59 Z"
                        id="Fill-6"
                        fill={color || "#FFFFFF"}
                    ></path>
                    <polygon
                        id="Fill-7"
                        fill={color || "#FFFFFF"}
                        points="204 59 204 0 223.272583 0 228.727031 19.9740738 228.848374 19.9740738 228.848374 0 244 0 244 59 224.726988 59 219.27254 39.0259262 219.151197 39.0259262 219.151197 59"
                    ></polygon>
                    <g id="Group-10" transform="translate(247.000000, 0.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="Clip-9"></g>
                        <path
                            d="M22.5006865,40.6852515 C19.5873792,40.6852515 19.6422987,37.9520149 19.6422987,30.690847 C19.6422987,23.4222685 19.5654972,20.6960067 22.5006865,20.6960067 C25.4135647,20.6960067 25.3573581,23.4327307 25.3573581,30.690847 C25.3573581,37.9620411 25.4358758,40.6852515 22.5006865,40.6852515 M22.4998284,-0.000305146034 C4.01512956,-0.000305146034 0,12.3232354 0,30.4999128 C0,48.8614215 4.15371546,61.0001308 22.4998284,61.0001308 C40.9845272,61.0001308 45.0000858,48.6770261 45.0000858,30.4999128 C45.0000858,12.1384041 40.8463704,-0.000305146034 22.4998284,-0.000305146034"
                            id="Fill-8"
                            fill={color || "#FFFFFF"}
                            mask="url(#mask-2)"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);



export default ItalianoLogo;
