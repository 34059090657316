import React from 'react';
import styled from 'styled-components'
import { Section, colors, mediaDown, mediaUp, StyledLink } from '../styles'
import ItalianoLogo from '../Logo/ItalianoLogo'

const FooterSection = styled(Section)`
    font-size: 1.1rem;
    justify-content: space-around;
    ${mediaDown.phone`
        padding: 1rem;
        `}
    `
const Column = styled.div`
      flex-basis: 25%;
      `
const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    justify-content: space-around;
    align-content: stretch;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    ${mediaUp.tablet`
        padding-left: 3rem;
        padding-right: 3rem;
    `}
    ${mediaDown.tablet`
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex-wrap: nowrap;
        `}
    `

const SocialMediaLink = styled.a`
    color: inherit;
    display: block;
    text-decoration: none; 
    padding-top: .2em;
    padding-bottom: .2em;
    &:hover {
        text-decoration: underline;
    }
    `
const List = styled.ul`
      display: flex;
      flex-direction: column;
      padding-left: 0;
      `

const Item = styled.li`
    display: block;
    list-style: none;
    padding-top: ${p => !p.hasLink ? .2 : 0}em;
    padding-bottom: ${p => !p.hasLink ? .2 : 0}em;
    `
const LogoWrap = styled.div`
    display: flex;
    margin-bottom: 1rem;
    justify-self: flex-start;
    float: left;
    `

const Footer = props => {
    return (
        <FooterSection>
            <Container>
                <Column>
                    <List>
                        <Item>
                            <LogoWrap>
                                <ItalianoLogo width="120" color="#000" />
                            </LogoWrap>
                        </Item>
                        <Item hasLink>
                            <SocialMediaLink href="http://italiano.se/">Italiano</SocialMediaLink>
                        </Item>
                        <Item hasLink>
                            <SocialMediaLink href="http://italiano.se/papa">Pap&agrave;</SocialMediaLink>
                        </Item>
                        <Item hasLink>
                            <SocialMediaLink href="http://italiano.se/bar">Italiano Bar</SocialMediaLink>
                        </Item>
                    </List>
                </Column>
                <Column>
                    <List>
                        <Item>Gruppo Italiano AB</Item>
                        <Item>Nybrogatan 12</Item>
                        <Item>SE-114 39 Stockholm</Item>
                        <Item><StyledLink href="mailto:ciao@italiano.se">ciao@italiano.se</StyledLink></Item>
                        <Item><StyledLink href="mailto:jobb@italiano.se"><b>Join us!</b></StyledLink></Item>
                    </List>
                </Column>
                <Column>
                    <List>
                        <Item>CICCIO'S</Item>
                        <Item>Nybrogatan 21</Item>
                        <Item>SE-114 39 Stockholm</Item>
                        <Item><StyledLink href="mailto:ciao@ciccios.se">ciao@ciccios.se</StyledLink></Item>
                        <Item><StyledLink href="tel:08-661 89 76">08-661 89 76</StyledLink></Item>
                    </List>
                </Column>
                <Column>
                    <List>
                        <Item>Follow us: </Item>
                        <Item hasLink>
                            <SocialMediaLink href="https://www.facebook.com/ciccios.se/">Facebook</SocialMediaLink>
                        </Item>
                        <Item hasLink>
                            <SocialMediaLink href="http://instagram.com/ciccios.se">Instagram</SocialMediaLink>
                        </Item>
                    </List>
                </Column>
            </Container>
        </FooterSection>
    );
}

export default Footer;
