import React from 'react';

const Logo = props => {
    return (
        <svg
            className="logo-svg"
            width={props.width || 258}
            height={props.width * 159/258 || 159}
            viewBox="0 0 258 159"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
        <defs></defs>
        <g id="Ciccio's_Logo_Italo-Americano" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M41.944,94.28 L30.83,91.691 C30.525,91.691 30.373,91.767 30.373,92.072 C30.373,96.564 29.683,100.148 25.344,100.137 C22.603,100.13 20.777,98.767 20.776,93.895 L20.785,64.067 C20.785,59.195 22.612,57.818 25.352,57.825 C29.925,57.837 30.372,61.696 30.373,66.188 C30.372,66.493 30.525,66.568 30.829,66.568 L41.944,63.979 C42.248,63.904 42.324,63.674 42.325,63.447 C41.106,53.474 32.731,51.19 25.347,51.19 C16.745,51.19 8.294,54.236 8.294,66.112 L8.295,92.148 C8.296,104.025 16.746,107.069 25.349,107.069 C32.733,107.069 41.107,104.785 42.325,94.811 C42.325,94.583 42.248,94.355 41.944,94.28" id="Fill-1" fill={props.color || '#131514'}></path>
            <path d="M100.562,94.427 L89.447,91.838 C89.143,91.838 88.991,91.915 88.99,92.219 C88.991,96.711 88.3,100.295 83.961,100.284 C81.221,100.277 79.394,98.915 79.394,94.042 L79.402,64.215 C79.402,59.343 81.229,57.966 83.97,57.973 C88.543,57.984 88.99,61.843 88.99,66.335 C88.99,66.64 89.142,66.715 89.447,66.715 L100.562,64.127 C100.865,64.051 100.941,63.822 100.942,63.594 C99.724,53.621 91.349,51.337 83.964,51.338 C75.362,51.337 66.911,54.383 66.912,66.26 L66.913,92.296 C66.913,104.172 75.363,107.216 83.966,107.216 C91.351,107.216 99.725,104.932 100.942,94.958 C100.942,94.73 100.866,94.502 100.562,94.427" id="Fill-2" fill={props.color || '#131514'}></path>
            <path d="M139.082,94.524 L127.967,91.935 C127.663,91.935 127.511,92.011 127.51,92.316 C127.511,96.808 126.82,100.392 122.481,100.381 C119.741,100.374 117.914,99.011 117.914,94.139 L117.923,64.312 C117.922,59.439 119.75,58.062 122.49,58.069 C127.063,58.081 127.51,61.94 127.51,66.433 C127.51,66.737 127.662,66.812 127.967,66.812 L139.082,64.224 C139.385,64.148 139.461,63.918 139.462,63.691 C138.244,53.718 129.869,51.434 122.484,51.435 C113.882,51.434 105.432,54.48 105.432,66.356 L105.433,92.393 C105.433,104.269 113.883,107.313 122.486,107.314 C129.871,107.314 138.245,105.029 139.462,95.055 C139.462,94.828 139.386,94.599 139.082,94.524" id="Fill-3" fill={props.color || '#131514'}></path>
            <path d="M59.756,52.73 L48.184,52.73 C47.879,52.73 47.727,52.882 47.727,53.187 L47.729,105.564 C47.729,105.869 47.881,106.021 48.185,106.021 L59.758,106.021 C60.062,106.021 60.214,105.869 60.214,105.564 L60.213,53.187 C60.212,52.883 60.06,52.73 59.756,52.73" id="Fill-4" fill={props.color || '#131514'}></path>
            <path d="M212.141,52.723 L204.316,52.719 C204.029,52.718 203.936,52.871 203.936,53.175 L203.904,65.964 C203.904,66.192 204.056,66.42 204.361,66.421 L208.788,66.421 C209.093,66.421 209.264,66.186 209.315,65.965 C209.367,65.744 212.376,53.456 212.444,53.18 C212.511,52.904 212.368,52.724 212.141,52.723" id="Fill-5" fill={props.color || '#131514'}></path>
            <path d="M156.894,52.728 L145.322,52.728 C145.018,52.728 144.866,52.88 144.866,53.185 L144.867,105.562 C144.868,105.866 145.019,106.019 145.324,106.019 L156.895,106.019 C157.2,106.019 157.353,105.866 157.353,105.561 L157.352,53.184 C157.352,52.879 157.199,52.728 156.894,52.728" id="Fill-6" fill={props.color || '#131514'}></path>
            <path d="M227.129,62.662 C227.136,59.773 229.386,58.344 231.822,58.344 C234.183,58.344 236.479,59.894 236.463,66.274 C236.463,66.579 236.69,66.58 236.919,66.579 L247.425,64.143 C247.729,64.066 247.806,63.914 247.805,63.61 C246.664,55.007 240.193,51.429 231.818,51.43 C223.292,51.43 216.059,55.77 216.059,65.666 C216.06,80.893 236.691,81.958 236.691,92.311 C236.691,96.878 236.502,100.668 231.661,100.655 C227.062,100.644 226.871,97.183 226.642,92.31 C226.643,92.083 226.566,91.931 226.262,91.93 L215.07,94.519 C214.843,94.595 214.766,94.747 214.766,95.051 C215.681,102.589 221.237,107.309 231.819,107.309 C240.955,107.309 249.148,102.512 249.176,91.549 C249.221,73.773 227.103,73.013 227.129,62.662" id="Fill-7" fill={props.color || '#131514'}></path>
            <path d="M185.667,93.838 C185.666,99.189 183.838,100.527 181.098,100.528 C178.357,100.528 176.531,99.192 176.532,93.84 L176.538,64.907 C176.539,59.556 178.366,58.217 181.106,58.217 C183.847,58.216 185.675,59.553 185.674,64.905 L185.667,93.838 Z M181.103,51.433 C173.337,51.433 164.049,54.098 164.049,66.202 L164.05,92.543 C164.05,104.647 173.338,107.312 181.104,107.312 C188.868,107.312 198.156,104.647 198.156,92.542 L198.155,66.202 C198.155,54.097 188.867,51.433 181.103,51.433 Z" id="Fill-8" fill={props.color || '#131514'}></path>
            <path d="M59.901,136.372 C59.996,136.372 60.042,136.324 60.043,136.229 L60.042,133.805 C60.042,133.71 59.996,133.663 59.901,133.662 L48.922,133.662 C48.828,133.663 48.78,133.71 48.779,133.805 L48.78,136.229 C48.78,136.324 48.828,136.372 48.922,136.371 L52.463,136.372 L52.464,150.153 C52.463,150.248 52.51,150.296 52.606,150.296 L56.217,150.295 C56.312,150.295 56.36,150.248 56.36,150.153 L56.36,136.372 L59.901,136.372" id="Fill-9" fill={props.color || '#131514'}></path>
            <path d="M46.521,133.663 L42.909,133.663 C42.814,133.663 42.767,133.71 42.767,133.805 L42.768,150.153 C42.768,150.248 42.814,150.296 42.909,150.296 L46.521,150.296 C46.616,150.295 46.664,150.248 46.664,150.153 L46.664,133.805 C46.664,133.71 46.616,133.662 46.521,133.663" id="Fill-10" fill={props.color || '#131514'}></path>
            <path d="M81.935,147.586 L76.85,147.586 L76.85,133.805 C76.85,133.71 76.802,133.662 76.707,133.662 L73.095,133.662 C73,133.662 72.953,133.71 72.953,133.805 L72.954,150.153 C72.954,150.248 73.001,150.295 73.095,150.295 L81.935,150.295 C82.03,150.294 82.078,150.247 82.078,150.152 L82.079,147.728 C82.078,147.633 82.03,147.585 81.935,147.586" id="Fill-11" fill={props.color || '#131514'}></path>
            <path d="M63.516,145.472 L64.704,140.791 L65.892,145.472 L63.516,145.472 Z M66.628,133.662 L63.848,133.663 C63.777,133.662 63.752,133.687 63.729,133.757 L59.358,150.153 C59.358,150.295 59.453,150.295 59.524,150.296 L62.114,150.295 C62.208,150.295 62.232,150.272 62.257,150.201 L62.803,148.18 L66.605,148.18 L67.151,150.201 C67.174,150.272 67.222,150.295 67.293,150.296 L70.976,150.295 C71.048,150.295 71.119,150.295 71.119,150.153 L66.77,133.757 C66.747,133.687 66.699,133.662 66.628,133.662 Z" id="Fill-12" fill={props.color || '#131514'}></path>
            <path d="M210.808,146.086 C210.808,147.607 210.237,147.987 209.381,147.987 C208.526,147.987 207.956,147.607 207.956,146.086 L207.956,137.865 C207.956,136.344 208.526,135.964 209.381,135.963 C210.237,135.963 210.808,136.344 210.808,137.865 L210.808,146.086 Z M209.381,133.254 C206.958,133.255 204.059,134.086 204.059,137.865 L204.059,146.086 C204.059,149.863 206.958,150.696 209.381,150.695 C211.805,150.695 214.704,149.864 214.704,146.086 L214.704,137.865 C214.704,134.086 211.805,133.254 209.381,133.254 Z" id="Fill-13" fill={props.color || '#131514'}></path>
            <path d="M163.414,133.66 L159.803,133.659 C159.707,133.659 159.66,133.708 159.66,133.803 L159.661,150.15 C159.661,150.246 159.707,150.293 159.803,150.293 L163.414,150.293 C163.509,150.293 163.557,150.245 163.557,150.149 L163.556,133.802 C163.557,133.707 163.509,133.66 163.414,133.66" id="Fill-14" fill={props.color || '#131514'}></path>
            <path d="M151.315,140.218 L150.698,140.219 L150.698,136.369 L151.315,136.369 C152.171,136.369 152.741,136.796 152.742,138.293 C152.742,139.791 152.171,140.219 151.315,140.218 Z M154.595,142.357 C155.759,141.692 156.639,140.456 156.638,138.293 C156.639,134.705 154.333,133.659 152.195,133.66 L146.943,133.66 C146.848,133.66 146.801,133.708 146.801,133.803 L146.802,150.15 C146.802,150.245 146.848,150.293 146.943,150.293 L150.555,150.293 C150.65,150.293 150.698,150.246 150.698,150.15 L150.698,142.927 L151.173,142.927 L153.408,150.198 C153.431,150.27 153.478,150.293 153.525,150.293 L157.232,150.293 C157.328,150.293 157.375,150.27 157.375,150.199 C157.375,150.174 157.375,150.127 157.352,150.056 L154.595,142.357 Z" id="Fill-15" fill={props.color || '#131514'}></path>
            <path d="M201.251,133.659 L198.638,133.658 C198.542,133.659 198.495,133.707 198.495,133.801 L198.496,141.191 L194.907,133.778 C194.859,133.683 194.788,133.658 194.716,133.659 L191.985,133.659 C191.889,133.659 191.842,133.707 191.842,133.801 L191.843,150.149 C191.842,150.244 191.889,150.292 191.985,150.293 L194.598,150.292 C194.693,150.293 194.741,150.244 194.741,150.149 L194.741,142.759 L198.306,150.172 C198.329,150.22 198.377,150.292 198.495,150.292 L201.251,150.293 C201.347,150.292 201.394,150.244 201.394,150.149 L201.393,133.801 C201.394,133.706 201.347,133.658 201.251,133.659" id="Fill-16" fill={props.color || '#131514'}></path>
            <path d="M171.875,135.965 C172.825,135.964 173.443,136.535 173.442,137.937 C173.442,138.032 173.49,138.056 173.585,138.055 L177.055,137.247 C177.149,137.223 177.174,137.152 177.174,137.081 C176.794,133.968 174.18,133.256 171.875,133.256 C169.19,133.256 166.552,134.206 166.552,137.913 L166.552,146.04 C166.553,149.747 169.19,150.696 171.875,150.696 C174.18,150.696 176.794,149.983 177.174,146.871 C177.174,146.799 177.15,146.728 177.055,146.704 L173.586,145.896 C173.491,145.897 173.443,145.92 173.443,146.016 C173.443,147.418 172.825,147.988 171.875,147.988 C171.02,147.988 170.449,147.561 170.449,146.04 L170.449,137.913 C170.449,136.392 171.02,135.965 171.875,135.965" id="Fill-17" fill={props.color || '#131514'}></path>
            <path d="M182.381,145.47 L183.569,140.787 L184.757,145.469 L182.381,145.47 Z M185.493,133.658 L182.713,133.659 C182.642,133.659 182.618,133.684 182.594,133.754 L178.222,150.149 C178.222,150.292 178.318,150.293 178.389,150.293 L180.979,150.292 C181.074,150.293 181.097,150.269 181.122,150.198 L181.668,148.177 L185.47,148.178 L186.017,150.197 C186.04,150.269 186.087,150.293 186.159,150.293 L189.842,150.292 C189.913,150.292 189.984,150.293 189.985,150.149 L185.636,133.753 C185.612,133.683 185.564,133.659 185.493,133.658 Z" id="Fill-18" fill={props.color || '#131514'}></path>
            <path d="M103.375,141.027 L97.221,141.027 C97.126,141.027 97.078,141.075 97.079,141.17 L97.079,142.786 C97.078,142.88 97.126,142.928 97.221,142.928 L103.375,142.928 C103.47,142.929 103.518,142.881 103.518,142.786 L103.519,141.17 C103.518,141.076 103.47,141.028 103.375,141.027" id="Fill-19" fill={props.color || '#131514'}></path>
            <path d="M90.468,146.089 C90.468,147.609 89.897,147.99 89.042,147.99 C88.187,147.99 87.616,147.61 87.616,146.089 L87.617,137.868 C87.616,136.347 88.187,135.967 89.042,135.967 C89.897,135.967 90.468,136.347 90.468,137.867 L90.468,146.089 Z M89.042,133.258 C86.619,133.257 83.72,134.09 83.72,137.868 L83.72,146.089 C83.72,149.867 86.619,150.698 89.042,150.698 C91.466,150.699 94.365,149.866 94.365,146.089 L94.365,137.868 C94.365,134.089 91.466,133.258 89.042,133.258 Z" id="Fill-20" fill={props.color || '#131514'}></path>
            <path d="M143.97,147.584 L138.885,147.585 L138.885,142.547 L143.304,142.547 C143.399,142.547 143.447,142.501 143.447,142.404 L143.447,139.981 C143.447,139.886 143.399,139.838 143.304,139.837 L138.885,139.838 L138.885,136.37 L143.97,136.369 C144.064,136.369 144.113,136.321 144.113,136.227 L144.112,133.803 C144.112,133.708 144.064,133.66 143.97,133.66 L135.131,133.66 C135.035,133.661 134.988,133.709 134.988,133.803 L134.989,150.151 C134.988,150.246 135.035,150.294 135.131,150.293 L143.97,150.293 C144.065,150.293 144.113,150.246 144.113,150.151 L144.113,147.727 C144.113,147.632 144.065,147.584 143.97,147.584" id="Fill-21" fill={props.color || '#131514'}></path>
            <path d="M109.389,145.471 L110.578,140.79 L111.765,145.471 L109.389,145.471 Z M112.501,133.661 L109.722,133.661 C109.65,133.661 109.626,133.686 109.603,133.756 L105.23,150.152 C105.23,150.294 105.326,150.294 105.397,150.295 L107.987,150.294 C108.082,150.294 108.105,150.271 108.13,150.2 L108.677,148.179 L112.479,148.179 L113.025,150.2 C113.048,150.271 113.096,150.294 113.167,150.293 L116.85,150.294 C116.921,150.294 116.993,150.294 116.993,150.152 L112.644,133.756 C112.621,133.686 112.573,133.661 112.501,133.661 Z" id="Fill-22" fill={props.color || '#131514'}></path>
            <path d="M131.657,133.66 L127.974,133.66 C127.879,133.66 127.784,133.708 127.76,133.827 L125.337,143.285 L122.913,133.803 C122.89,133.708 122.842,133.66 122.747,133.661 L119.159,133.661 C119.063,133.661 119.016,133.708 119.016,133.803 L119.017,150.151 C119.017,150.246 119.063,150.294 119.159,150.294 L121.582,150.294 C121.678,150.293 121.725,150.246 121.725,150.151 L121.725,143.213 L123.294,150.151 C123.317,150.246 123.388,150.294 123.483,150.294 L126.145,150.294 C126.24,150.293 126.312,150.246 126.335,150.151 L127.903,143.213 L127.903,150.151 C127.903,150.246 127.95,150.294 128.045,150.294 L131.658,150.293 C131.752,150.293 131.8,150.246 131.8,150.151 L131.8,133.803 C131.8,133.708 131.752,133.66 131.657,133.66" id="Fill-23" fill={props.color || '#131514'}></path>
            <path d="M150.654,9.121 C150.654,9.022 150.604,8.973 150.505,8.973 L148.646,8.975 C148.547,8.975 148.497,9 148.423,9.074 L146.689,10.786 C146.64,10.835 146.59,10.885 146.59,11.009 L146.604,26.182 C146.604,26.282 146.653,26.331 146.752,26.331 L150.521,26.328 C150.62,26.328 150.669,26.278 150.669,26.179 L150.654,9.121" id="Fill-24" fill={props.color || '#131514'}></path>
            <path d="M125.68,18.962 C125.634,18.917 125.573,18.895 125.496,18.895 L125.492,18.895 L118.821,18.9 C118.742,18.9 118.679,18.924 118.633,18.969 C118.588,19.015 118.564,19.079 118.565,19.157 L118.565,20.42 C118.566,20.578 118.664,20.676 118.822,20.676 L125.495,20.67 C125.652,20.669 125.751,20.57 125.75,20.414 L125.75,19.15 C125.75,19.071 125.726,19.007 125.68,18.962" id="Fill-25" fill={props.color || '#131514'}></path>
            <path d="M121.325,11.519 C121.324,10.727 121.729,10.564 122.153,10.563 L122.157,10.563 C122.58,10.564 122.983,10.728 122.984,11.518 L122.978,15.079 C122.979,15.872 122.574,16.034 122.15,16.035 L122.146,16.035 C121.724,16.034 121.321,15.871 121.319,15.08 L121.325,11.519 Z M119.898,17.355 C120.422,17.697 121.258,17.872 122.139,17.873 L122.158,17.873 C124.256,17.871 125.486,16.904 125.484,15.077 L125.478,11.522 C125.477,10.477 125.117,9.712 124.406,9.249 C123.882,8.907 123.047,8.733 122.165,8.731 L122.146,8.731 C120.048,8.734 118.818,9.7 118.819,11.527 L118.825,15.082 C118.827,16.127 119.187,16.893 119.898,17.355 Z" id="Fill-26" fill={props.color || '#131514'}></path>
            <path d="M116.621,8.83 L113.893,8.833 C113.794,8.833 113.744,8.882 113.744,8.98 L113.751,16.83 L110,8.959 C109.951,8.861 109.876,8.836 109.802,8.836 L106.95,8.839 C106.851,8.839 106.801,8.888 106.801,8.987 L106.816,26.184 C106.816,26.284 106.866,26.333 106.965,26.333 L109.693,26.331 C109.792,26.331 109.842,26.281 109.841,26.182 L109.835,18.47 L113.561,26.204 C113.586,26.253 113.635,26.327 113.759,26.327 L116.635,26.324 C116.735,26.324 116.784,26.275 116.784,26.175 L116.769,8.978 C116.769,8.879 116.72,8.83 116.621,8.83" id="Fill-27" fill={props.color || '#131514'}></path>
            <path d="M143.982,23.11 L137.834,23.116 C139.715,19.816 144.126,17.482 144.123,13.416 C144.119,9.772 141.44,8.56 138.564,8.562 C136.011,8.564 133.037,9.707 133.04,12.608 C133.04,12.682 133.089,12.707 133.164,12.732 L136.784,13.572 C136.858,13.571 136.908,13.546 136.908,13.447 C136.907,12.307 137.551,11.389 138.567,11.388 C139.509,11.388 140.055,11.982 140.056,13.42 C140.06,16.99 133.12,19.625 133.027,26.194 C133.027,26.293 133.102,26.343 133.176,26.343 L143.985,26.333 C144.085,26.333 144.134,26.284 144.134,26.184 L144.131,23.259 C144.131,23.16 144.082,23.11 143.982,23.11" id="Fill-28" fill={props.color || '#131514'}></path>
        </g>
        </svg>
    );
}

export default Logo;
