import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'
import Spinner from './spinner'

const Screen = styled.div`
    background: ${colors.dark};
    color: ${colors.apricot};
    height: 100vh;
    width: 100vw;
    position: relative;
    `

const Holder = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    `



const LoadingScreen = () => {
    return (
        <Screen>
            <Holder>
                <Spinner /> 
            </Holder>
        </Screen>
    )
}

export default LoadingScreen