import React, { Component } from "react";
import TweenLite, { TimelineLite } from "gsap";
import styled from "styled-components";
import { colors } from "../../styles";

const CloseButton = styled.a`
  color: ${colors.apricot};
  cursor: pointer;
  position: absolute;
  max-height: 50px;
  left: 50%;
  top: -25px;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "CentralAvenue";
  display: flex;
  align-items: center;
  text-decoration: none;
  &::before {
    content: "\f057";
    font-family: "Font Awesome 5 Pro";
    margin-right: 0.4em;
  }
`;

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalWrap = null;
    this.modalDialog = null;
    this.modalTween = new TimelineLite({
      paused: true,
      onComplete: () => this.onComplete(),
      onReverseComplete: () => this.onReverseComplete()
    });

    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.state = {
      closed: false
    };
  }

  onComplete = () => {};

  onReverseComplete = () => {
    this.push();
  };
  keyDownHandler = e => {
    if (e.keyCode === 27) this.close();
  };

  componentDidMount() {
    this.modalTween
      .to(this.modalWrap, 0.01, { autoAlpha: 1 })
      .to(this.closeBtn, 0.01, { autoAlpha: 1 })
      .to(this.modalDialog, 0.25, { y: 50, autoAlpha: 1 }, 0)
      .reversed(true)
      .paused(false);
  }
  close(e) {
    e && e.preventDefault();
    this.setState({ closed: true });
    this.props.close();
  }
  push() {
    this.state.closed && this.props.history.push({ pathname: "/" });
  }
  componentDidUpdate() {
    if (this.props.visible) {
      window.addEventListener("keydown", this.keyDownHandler);
    } else {
      window.removeEventListener("keydown", this.keyDownHandler);
    }
    this.modalTween.reversed(!this.props.visible);
  }

  render() {
    return (
      <div
        className="modal"
        ref={div => (this.modalWrap = div)}
        onClick={this.props.close}
      >
        <div
          className="modal-dialog"
          ref={div => (this.modalDialog = div)}
          onClick={e => e.stopPropagation()}
        >
          <CloseButton
            className="modal-close"
            onClick={e => this.close(e)}
            innerRef={a => (this.closeBtn = a)}
          >
            Close
          </CloseButton>
          <div className="modal-content">
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
