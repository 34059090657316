import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends React.Component {
  render() {
    const bodyBackground = "#fffffa";
    return (
      <Map
        google={this.props.google}
        zoom={14}
        initialCenter={{ lat: 59.335433, lng: 18.077643 }}
        styles={[
          {
            featureType: "administrative.locality",
            elementType: "all",
            stylers: [
              { hue: "#2c2e33" },
              { saturation: 7 },
              { lightness: 19 },
              { visibility: "on" }
            ]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              { hue: bodyBackground },
              { saturation: -100 },
              { lightness: 100 },
              { visibility: "simplified" }
            ]
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              { hue: bodyBackground },
              { saturation: -100 },
              { lightness: 100 },
              { visibility: "off" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              { hue: "#bbc0c4" },
              { saturation: -93 },
              { lightness: 31 },
              { visibility: "simplified" }
            ]
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              { hue: "#bbc0c4" },
              { saturation: -93 },
              { lightness: 31 },
              { visibility: "on" }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels",
            stylers: [
              { hue: "#bbc0c4" },
              { saturation: -93 },
              { lightness: -2 },
              { visibility: "simplified" }
            ]
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              { hue: "#e9ebed" },
              { saturation: -90 },
              { lightness: -8 },
              { visibility: "simplified" }
            ]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              { hue: "#e9ebed" },
              { saturation: 10 },
              { lightness: 69 },
              { visibility: "on" }
            ]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              { hue: "#e9ebed" },
              { saturation: -78 },
              { lightness: 67 },
              { visibility: "simplified" }
            ]
          }
        ]}
      >
        <Marker onClick={this.onMarkerClick} name={"Ciccio's"} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAE6hW5YLes_g0KxiqDNIHov5cFSFci8ig"
})(MapContainer);

// Italiano.se AIzaSyAC3butOlbLsFtIv6BHUjNH_SELMPUQff0
