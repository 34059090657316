import React, { Component } from "react";
import NavItems, { navItemStyle, NavItem } from "./NavItems";
import styled from "styled-components";

import { breakpoints } from "../../styles";
import scrollToHash from "./animateScroll";

export const ContactLink = styled(NavItem)`
  ${navItemStyle}
  font-family: 'GravurCondLight';
  @media (${breakpoints.tablet.min}) {
    font-size: 1.1rem;
  }
  @media (${breakpoints.tablet.max}) {
    display: none;
  }
  text-transform: capitalize;
`;

const NavBar = styled.nav`
  display: flex;
  margin: 0 auto;
  max-width: 75rem;
  width: 100vw;
  justify-content: space-around;
`;

class Nav extends Component {
  state = {
    navRect: {
      top: undefined,
      bottom: undefined
    },
    navIsAttatched: false,
    navIsSticky: false,
    topFixOffset: {
      top: undefined,
      padding: undefined,
      total: undefined
    }
  };

  navigate = to => {
    scrollToHash(to);
  };
  render() {
    return (
      <React.Fragment>
        <NavBar>
          <NavItems />
          <ContactLink
            handleClick={this.navigate}
            hide="phoneDown"
            to="#contact"
          >
            Contact
          </ContactLink>
        </NavBar>
      </React.Fragment>
    );
  }
}

export default Nav;
