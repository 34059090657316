import React, { Component } from 'react'
import Modal from './Modal'
import './modal.css'
import LoadingScreen from '../LoadingScreen'


class BookingWidget extends Component {
    state = {
        modalVisible: false,
        loading: true
    }
    componentDidMount() {
        window.addEventListener(
            "message", 
            this.iframePosted
            , false);
    }
    iframePosted = e => { 
        e.origin === "https://app.waiteraid.com" && this.open()
    }
    open = () => {
        this.setState({ loading: false, modalVisible: true });
    }
    close = () => {
        this.setState({ modalVisible: false })
    }
    componentWillUnmount () {
        window.removeEventListener("message", this.iframePosted)
    }
    render () {
        return (
            <React.Fragment>
            {this.state.loading && <LoadingScreen />}
            <Modal {...this.props} close={this.close} visible={this.state.modalVisible} id="booking">
                <iframe 
                    ref={frame => this.frame = frame} 
                    name="booking-frame" 
                    title="booking-frame" 
                    src="https://app.waiteraid.com/reservation/?hash=6efc74a27b57165fbfb16acbebf7d248&version=new" 
                    frameBorder="0" width="100%" height="100%"></iframe>
            </Modal>
            </React.Fragment>
        )
    }
}

export default BookingWidget

